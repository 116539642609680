<template>
  <div class="apple-tree-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="currentIndex"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <SceneSwitchThumbnails
      :buttons="buttonListImported ? buttonListImported : buttonList"
      :numBers="numBers"
      @changeTheNumbers="changeTheNumbers"
    >
    </SceneSwitchThumbnails>
    <div class="game-content">
      <!-- <Star :totalStarNumber="3" :currentIndex="currentIndex" /> -->
      <div class="bg-img">
        <img :src="bgImg" alt="" />
      </div>
      <div class="person-and-tree-area">
        <div class="person-area">
          <div
            class="person-item"
            v-for="(item, index) in personImgList"
            :key="index + 'person'"
          >
            <img :src="item" alt="" v-if="index === currentIndex" />
          </div>
        </div>

        <div class="tree-area">
          <div class="trea-box">
            <div
              class="tree-item"
              v-for="(item, index) in treeImgList"
              :key="index + 'tree'"
            >
              <img :src="item" alt="" :class="{isHidden : !(index === clickIndex)}" />

            </div>
          </div>
          <div class="basket-area">
            <div
              class="basket-item"
              v-for="(item, index) in basketImglIst"
              :key="index + 'person'"
            >
              <img
                :src="item"
                alt=""
                :class="{isHidden: !(index === clickIndex)}"
                @click="handleClickBasket('socket')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from "../Star";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";
export default {
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    personImgList: {
      type: Array,
      require: true,
    },
    treeImgList: {
      type: Array,
      require: true,
    },
    basketImglIst: {
      type: Array,
      require: true,
    },
    answerList: {
      type: Array,
      require: true,
    },
    buttonListImported: {
      type: Array,
      require: false,
    },
  },
  components: {
    // Star,
    SceneSwitchThumbnails,
    PageButton,
  },
  data() {
    return {
      socketInfo: {},
      clickIndex: 0,
      currentIndex: 0,
      isLastStep: false,
      numBers:1,
      menuIndex: 0,
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G60-phone/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G60-phone/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G60-phone/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 2,
            numBerValueAfterClick: null,
          },
          {
            type: "next",
            startNumberRange: 3,
            endNumberRange: 3,
            numBerValueAfterClick: 3,
          },
        ],
      },
    };
  },
  created() {
    // localStorage.handleClickBasket
  },
  mounted() {
    this.$bus.$on("clickBasketArea", (val) => {
      this.handleClickBasket(val, true);
    });
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20029,
          data: { value },
          text: "AppleTreeGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index, isFromSocket = false) {
      console.log(this.currentIndex);

      this.menuIndex = index - 1;
      this.currentIndex=this.menuIndex
      console.log(index);
      if (!isFromSocket) {
        this.clickAsideSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.numBers = index;
      this.$bus.$emit("controlCanvas", "clear");
      console.log(this.menuIndex);
      
      // this.currentPinyinInfo = this.pinyinImgList[this.menuIndex];
    },
    handleClickBasket(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketInfo = {
          index: Math.random(),
        };
      }
      if (this.clickIndex >= this.answerList[this.currentIndex]) {
        return;
      }
      this.clickIndex++;
      if (this.clickIndex === this.answerList[this.currentIndex]) {
        startConfetti();
        playCorrectSound();
        setTimeout(() => {
          this.currentIndex++;
          this.clickIndex = 0;
          if (this.currentIndex === this.answerList.length) {
            this.isLastStep = true;
          }
        }, 1000);
      } else {
        playCorrectSound(true, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.apple-tree-game-container {
  // width: 100%;
  // height: 100%;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bg-img {
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .person-and-tree-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      .person-area {
        flex: 4;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .person-item {
          display: flex;
          justify-content: center;
          padding-bottom: 15%;
          img {
            width: 65%;
          }
        }
      }
      .tree-area {
        flex: 5;
        height: 100%;
        position: relative;
        .trea-box {
          height: 100%;
          position: relative;
        }
        .tree-item {
          position: absolute;
          bottom: 8%;
          display: flex;
          justify-content: center;
          img {
            width: 65%;
          }
        }
        .basket-area {
          position: absolute;
          bottom: 10%;
          left: 0%;
          display: flex;
          align-items: flex-end;
          .basket-item {
            img {
              width: 70%;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
