<template>
  <div class="game-container">
    <PhoneNumberGame :callList="callList" />
  </div>
</template>

<script>
import PhoneNumberGame from "@/components/Course/GamePage/PhoneNumberGame";
export default {
  data() {
    return {
      callList: [
        {
          id: 8,
          image: require("@/assets/img/16-GAMES/G23-phone-number/laoshi-card.svg"),
          sound: require("@/assets/audio/1-9/3-1-9-8-7-6-7-5.mp3"),
          number: "31987675",
          pinying: "",
          hanzi: "",
          show: true,
          showLock: false,
        },
      ],
    };
  },
  components: {
    PhoneNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

