<!--
 * @Author: shmily
 * @Date: 2022-04-07 10:26:41
 * @LastEditTime: 2022-04-07 10:26:41
 * @LastEditors: shmily
 * @Description: 
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter1\Lesson8\17_ReviewFour copy.vue
-->
<template>
  <div class="game-container">
    <ReviewTrain
      :step="4"
      :vocabularyReviewList="vocabularyReviewList"
      :knowledgeReviewList="knowledgeReviewList"
      :pinyinWordReviewList="pinyinWordReviewList"
      :strokesReviewList="strokesReviewList"
    />
  </div>
</template>

<script>
import ReviewTrain from "@/components/Course/CoursePage/ReviewTrain";
export default {
  data() {
    return {
      vocabularyReviewList: [
        [
          {
            pinyin: "nǐ",
            hanzi: "你",
          },
          {
            pinyin: "wǒ",
            hanzi: "我",
          },
          {
            pinyin: "shì",
            hanzi: "是",
          },
          {
            pinyin: "hǎo",
            hanzi: "好",
          },
          {
            pinyin: "yī",
            hanzi: "一",
          },
          {
            pinyin: "èr",
            hanzi: "二",
          },
        ],
        [
          {
            pinyin: "sān",
            hanzi: "三",
          },
          {
            pinyin: "sì",
            hanzi: "四",
          },
          {
            pinyin: "wǔ",
            hanzi: "五",
          },
          {
            pinyin: "liù",
            hanzi: "六",
          },
          {
            pinyin: "qī",
            hanzi: "七",
          },
        ],
        [
          {
            pinyin: "bā",
            hanzi: "八",
          },
          {
            pinyin: "jiǔ",
            hanzi: "九",
          },
          {
            pinyin: "shí",
            hanzi: "十",
          },
          {
            pinyin: "lǎoshī",
            hanzi: "老师",
          },
          {
            pinyin: "zàijiàn",
            hanzi: "再见",
          },
        ],
      ],
      knowledgeReviewList: [
        [
          {
            pinyin: "Nǐ hǎo! Wǒ shì ...",
            hanzi: "你好！我是……",
            isFullWidth: true
          },
        ],
        [
          {
            pinyin: "Lǎoshī hǎo!",
            hanzi: "老师好！",
            isFullWidth: true
          },
        ],
        [
          {
            pinyin: "Zàijiàn!",
            hanzi: "再见！",
            isFullWidth: true
          },
        ],
      ],
      pinyinWordReviewList: [
        [
          {
            vocabulary: "a",
          },
          {
            vocabulary: "o",
          },
          {
            vocabulary: "e",
          },
          {
            vocabulary: "i",
          },
          {
            vocabulary: "u",
          },
          {
            vocabulary: "ü",
          },
        ],
        [
          {
            vocabulary: "ā",
          },
          {
            vocabulary: "á",
          },
          {
            vocabulary: "ǎ",
          },
          {
            vocabulary: "à",
          },
        ],
      ],
      strokesReviewList: [
        [
                    {
            strokeImg: require("@/assets/img/11-Syllabus/lesson-04-san-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/11-Syllabus/lesson-02-ni-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/11-Syllabus/lesson-04-wu-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-02-shu-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-02-heng-yellow.svg"),
          },

        ],
        [
          {
            strokeImg: require("@/assets/img/11-Syllabus/lesson-06-shi-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/11-Syllabus/lesson-06-ba-white.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-06-pie-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-06-na-yellow.svg"),
          },
          {
            strokeImg: require("@/assets/img/18-Blue-review/lesson-04-hengzhe-yellow.svg"),
          },
        ],
      ],
    };
  },
  components: {
    ReviewTrain,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>