<template>
  <div class="game-container">
    <RecognitionPinyinGame
      :optionList="optionList"
      :imgList="imgList"
      :isHaveAudio="true"
      :limitNumberOfVisibleOptions="3"
      :isFullScreen="true"
    />
  </div>
</template>

<script>
import RecognitionPinyinGame from "@/components/Course/GamePage/RecognitionPinyinGame";
export default {
  data() {
    return {
      lessonId: 8,
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-a.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-o.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-e.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-i.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-u.svg"),
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/background-ü.svg"),
        },
      ],

      imgList: [
        {
          id: 1,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-a.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/a.mp3"),
        },
        {
          id: 2,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-o.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/o.mp3"),
        },
        {
          id: 3,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-e.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/e.mp3"),
        },
        {
          id: 4,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-i.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/i.mp3"), // TO DO : replace file here
        },
        {
          id: 5,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-u.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/u.mp3"),// TO DO : replace file here
        },
        {
          id: 6,
          bottomImg: require("@/assets/img/16-GAMES/G51-frogs/chapter-01/frog-ü.svg"),
          nobgcolor: true,
          audioSrc: require("@/assets/audio/L1/2-Pinyin/v.mp3"),
        },
      ],
    };
  },
  components: {
    RecognitionPinyinGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











