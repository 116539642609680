<template>
  <div class="game-container">
    <RainDropGame
      :optionsList="optionsList"
      :answerList="answerList"
      :bgImage="bgImage"
      @changeAnswerStatus="changeAnswerStatus"
      :starNum="3"
    />
  </div>
</template>

<script>
import RainDropGame from "@/components/Course/GamePage/RainDropGame";
export default {
  data() {
    return {
      bgImage: require("@/assets/img/16-GAMES/G53-drops/background.svg"),
      optionsList: [
        [
          {
            index: 3,
            image: require("@/assets/img/16-GAMES/G53-drops/drop-san.svg"),
            isAnswer: true,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ni.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ba.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ba.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ni.svg"),
            isAnswer: true,
            index: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-wu.svg"),
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-san.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-ba.svg"),
          },
          {
            image: require("@/assets/img/16-GAMES/G53-drops/drop-wu.svg"),
            isAnswer: true,
            index: 2,
          },
        ],
      ],

      answerList: [
        [
          {
            hanzi: "一",
            pinying: "yī",
            isChooseCorrect: true,
          },
          {
            hanzi: "二",
            pinying: "èr",
            isChooseCorrect: true,
          },
          {
            id: 3,
            hanzi: "三",
            pinying: "sān",
            isChooseCorrect: false,
          },
        ],
        [
          {
            id: 1,
            hanzi: "你",
            pinying: "ni",
            isChooseCorrect: false,
          },
          {
            hanzi: "好",
            pinying: "hǎo",
            isChooseCorrect: true,
          },
        ],
        [
          {
            hanzi: "四",
            pinying: "sì",
            isChooseCorrect: true,
          },
          {
            id: 2,
            hanzi: "五",
            pinying: "wǔ",
            isChooseCorrect: false,
          },
          {
            hanzi: "六",
            pinying: "liù",
            isChooseCorrect: true,
          },
        ],
      ],
    };
  },
  components: {
    RainDropGame,
  },
  methods: {
    changeAnswerStatus(item, index) {
      this.answerList[index].forEach((ele) => {
        if (ele.id === item.index) {
          ele.isChooseCorrect = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











