<template>
  <div class="game-container">
    <BingoGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      @changeStatus="changeStatus"
      :bingoIndexList="[]"
    />
  </div>
</template>

<script>
import BingoGame from "@/components/Course/GamePage/BingoGame";
export default {
  data() {
    return {
      totalStars: 5,
      questionInfoList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G48-9-numbers/count-1.svg"),
        },

        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G48-9-numbers/count-3.svg"),
        },
        {
          id: 5,
          bgImg: require("@/assets/img/16-GAMES/G48-9-numbers/count-5.svg"),
        },
        {
          id: 6,
          bgImg: require("@/assets/img/16-GAMES/G48-9-numbers/count-6.svg"),
        },
        {
          id: 9,
          bgImg: require("@/assets/img/16-GAMES/G48-9-numbers/count-9.svg"),
        },
      ],
      nameString: ["leftList", "MiddleList", "rightList"],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            element: "一",
            pinyin: "yī",
            done: false,
          },
          {
            id: 2,
            element: "二",
            pinyin: "èr",
            done: false,
          },
          {
            id: 4,
            element: "四",
            pinyin: "sì",
            done: false,
          },
        ],
        MiddleList: [
          {
            id: 3,
            element: "三",
            pinyin: "sān",
            done: false,
          },
          {
            id: 9,
            element: "九",
            pinyin: "jiǔ",
            done: false,
          },
          {
            id: 5,
            element: "五",
            pinyin: "wǔ",
            done: false,
          },
        ],
        rightList: [
          {
            id: 7,
            element: "七",
            pinyin: "qī",
            done: false,
          },
          {
            id: 6,
            element: "六",
            pinyin: "liù",
            done: false,
          },
          {
            id: 8,
            element: "八",
            pinyin: "bā",
            done: false,
          },
        ],
      },
    };
  },
  components: {
    BingoGame,
  },
  methods: {
    changeStatus(item) {
      // console.log(item);
      for (let i = 0; i <= 2; i++) {
        this.asideElementInfo[this.nameString[i]].forEach((ele) => {
          if (ele.id === item.id) {
            ele.done = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
