<template>
  <div class="game-container">
    <ListenThenChooseWordsGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :chooseHanziList="chooseHanziList"
    />
  </div>
</template>

<script>
import ListenThenChooseWordsGame from "@/components/Course/GamePage/ListenThenChooseWordsGame";
export default {
  data() {
    return {
      answerList: [2, 7, 12, 15],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-2.svg`),
          wordsPinyin: "Nǐ hǎo!",
          wordsHanzi: "你好！",
          audioSrc: require("@/assets/audio/L1/5-Words/nihao.mp3"),
          hanziRightList: [
            {
              pinyin: "nǐ",
              hanzi: "你",
              id: 0,
            },
            {
              pinyin: "hǎo",
              hanzi: "好",
              id: 1,
            },

          ],
          hanziChooseList: [
            {
              pinyin: "hǎo",
              hanzi: "好",
              id: 1,
            },
            {
              pinyin: "nǐ",
              hanzi: "你",
              id: 0,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-7.svg`),
          wordsPinyin: "Zàijiàn!",
          wordsHanzi: "再见！",
          audioSrc: require("@/assets/audio/L1/5-Words/zaijian.mp3"),
          hanziRightList: [
            {
              pinyin: "zài",
              hanzi: "再",
              id: 0,
            },
            {
              pinyin: "jiàn",
              hanzi: "见",
              id: 1,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "zài",
              hanzi: "再",
              id: 0,
            },
            {
              pinyin: "nǐ",
              hanzi: "你",
              id: 2,
            },
            {
              pinyin: "jiàn",
              hanzi: "见",
              id: 1,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-12.svg`),
          wordsPinyin: "Lǎoshī hǎo!",
          wordsHanzi: "老师好!",
          audioSrc: require("@/assets/audio/L1/6-Sentences/laoshi-hao.mp3"),
          hanziRightList: [
            {
              pinyin: "lǎo",
              hanzi: "老",
              id: 0,
            },
            {
              pinyin: "shī",
              hanzi: "师",
              id: 1,
            },
            {
              pinyin: "hǎo",
              hanzi: "好",
              id: 2,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "shī",
              hanzi: "师",
              id: 1,
            },
            {
              pinyin: "hǎo",
              hanzi: "好",
              id: 2,
            },
            {
              pinyin: "lǎo",
              hanzi: "老",
              id: 0,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 3,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-15.svg`),
          wordsPinyin: "Wǒ shì Dōngdōng.",
          audioSrc: require("@/assets/audio/L1/6-Sentences/wo-shi-dongdong.mp3"),
          wordsHanzi: "我是冬冬。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "shì",
              hanzi: "是",
              id: 1,
            },
            {
              pinyin: "dōng",
              hanzi: "冬",
              id: 2,
            },
            {
              pinyin: "dōng",
              hanzi: "冬",
              id: 2,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "shì",
              hanzi: "是",
              id: 1,
            },
            {
              pinyin: "dōng",
              hanzi: "冬",
              id: 2,
            },
            {
              pinyin: "wǔ",
              hanzi: "五",
              id: 3,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 0,
            },
            {
              pinyin: "dōng",
              hanzi: "冬",
              id: 2,
            },
          ],
        },
      ],
    };
  },
  computed: {
    allImgList() {
      let imgArr = [];
      for (let i = 1; i <= 16; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/chapter-01/story-${i}.svg`),
          id: i,
        });
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(8, 12),
        imgArr.slice(12, 16),
      ];
    },
  },
  components: {
    ListenThenChooseWordsGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>