<template>
  <div class="game-container">
    <AppleTreeGame
      :bgImg="bgImg"
      :personImgList="personImgList"
      :basketImglIst="basketImglIst"
      :treeImgList="treeImgList"
      :answerList="answerList"
      :buttonListImported="buttonList"
    />
  </div>
</template>

<script>
import AppleTreeGame from "@/components/Course/GamePage/AppleTreeGame";
export default {
  data() {
    return {
      answerList: [3, 8, 10],
      bgImg: require("@/assets/img/16-GAMES/G47-tree/background.svg"),
      personAr: ["boy", "teacher", "elephant"],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G47-tree/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G47-tree/menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G47-tree/menu-2.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
  computed: {
    personImgList() {
      let imgArr = [];
      for (let i = 0; i <= 2; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G47-tree/person-${this.personAr[i]}.svg`)
        );
      }
      return imgArr;
    },
    treeImgList() {
      let imgArr = [];
      for (let i = 0; i <= 10; i++) {
        imgArr.push(require(`@/assets/img/16-GAMES/G47-tree/tree-${i}.svg`));
      }
      return imgArr;
    },
    basketImglIst() {
      let imgArr = [];
      for (let i = 0; i <= 10; i++) {
        imgArr.push(require(`@/assets/img/16-GAMES/G47-tree/basket-${i}.svg`));
      }
      return imgArr;
    },
  },
  components: {
    AppleTreeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>